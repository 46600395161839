import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const ContactList = () => {
  const { getAllContactList, deleteContactApi } = useApiAxios();
  const [contactData, setContactData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null); // Store the contact ID to delete

  const fun = async () => {
    getAllContactList()
      .then((response) => {
        setContactData(response.data.data.contacts);
      })
      .catch((error) => {
        setContactData([]);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fun();
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(0);
  };

  const handleDeleteContact = async () => {
    if (contactToDelete) {
      await deleteContactApi(contactToDelete);
      await fun(); // Refresh contact list after deletion
    }
    setShowDeleteModal(false); // Close the modal
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = contactData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
       
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="pb-2 fw-bold">Dashboard</h2>
                  <ul className="breadcrumbs">
                    <li className="nav-home">
                      <Link to="/">
                        <i className="flaticon-home" />
                      </Link>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <Link to="#">Dashboard</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


          <div className="page-inner mt--5">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">ContactList</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id="add-row"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>About</th>
                            <th style={{ width: "10%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((contact) => (
                            <tr key={contact._id}>
                              <td>{contact.name}</td>
                              <td>{contact.email}</td>
                              <td>{contact.about}</td>
                              <td>
                                <div className="form-button-action">
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title="Delete"
                                    className="btn btn-link btn-danger"
                                    data-original-title="Remove"
                                    onClick={() => {
                                      setContactToDelete(contact._id); // Set the contact to be deleted
                                      setShowDeleteModal(true); // Show the delete confirmation modal
                                    }}
                                  >
                                    <i className="fa fa-times" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-between align-items-center pagination-container">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(
                            contactData.length / itemsPerPage
                          )}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          subContainerClassName={"pages pagination"}
                          containerClassName={"pagination m-0"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                        <div className="form-group d-flex align-items-center items-per-page">
                          <label htmlFor="itemsPerPage" className="mr-2">
                            Items per page:
                          </label>
                          <select
                            id="itemsPerPage"
                            className="form-control"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                          >
                            <option value={5}>05</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          id="deleteConfirmationModal"
          tabIndex={-1}
          role="dialog"
          aria-hidden={showDeleteModal ? "false" : "true"}
          style={{ display: showDeleteModal ? "block" : "none" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Confirmation</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowDeleteModal(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this contact?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteContact} // Proceed with deletion on "Yes"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteModal(false)} // Close modal on "No"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default ContactList;
