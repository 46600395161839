import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useApiAxios from '../../api/useApiAxios';
import { useLocation } from 'react-router-dom';

const EditVendor = () => {
    const location = useLocation();
    const vendor = location.state?.vendor;

    const { updateVendorApi } = useApiAxios();

    const initialValues = {
        name: vendor ? vendor.name : '',
        email: vendor ? vendor.email : '',
        address: vendor ? vendor.address : '',
        pincode: vendor ? vendor.pincode : '',
        state: vendor ? vendor.state : '',
        // Add other fields here and set their initial values from vendor data
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[A-Za-z]+$/, 'Name must contain only alphabets')
            .required('Name is required')
            .max(25, 'Name must be at most 25 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
            .max(50, 'Email must be at most 50 characters'),
        address: Yup.string().required('Address is required'),
        pincode: Yup.string().required('Pincode is required'),
        state: Yup.string().required('State is required'),
        // Add validation for other fields here
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            // Update the vendor using the form values
            await updateVendorApi(vendor._id, values);
            alert('Vendor updated successfully!');
        } catch (error) {
            console.error('Error updating vendor:', error);
        }
        setSubmitting(false);
    };

    const formik = useFormik({
        initialValues,
     
        onSubmit,
    });

    return (
        <div>
                <div className="panel-header">
                    <div className="page-inner py-5">
                        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                            <div>
                                <h2 className="pb-2 fw-bold">Edit Vendor</h2>
                                <ul className="breadcrumbs">
                                    <li className="nav-home">
                                        <a href="#">
                                            <i className="flaticon-home" />
                                        </a>
                                    </li>
                                    <li className="separator">
                                        <i className="flaticon-right-arrow" />
                                    </li>
                                    <li className="nav-item">
                                        <a href="#">Vendor</a>
                                    </li>
                                    <li className="separator">
                                        <i className="flaticon-right-arrow" />
                                    </li>
                                    <li className="nav-item">
                                        <a href="#">Edit Vendor</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-inner mt--5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center">
                                        <h4 className="card-title">Vendor Details</h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">Name</label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        className={`form-control ${formik.errors.name && formik.touched.name ? 'is-invalid' : ''}`}
                                                        value={formik.values.name}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.name && formik.touched.name && <div className="invalid-feedback">{formik.errors.name}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className={`form-control ${formik.errors.email && formik.touched.email ? 'is-invalid' : ''}`}
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.email && formik.touched.email && <div className="invalid-feedback">{formik.errors.email}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="address">Address</label>
                                                    <input
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        className={`form-control ${formik.errors.address && formik.touched.address ? 'is-invalid'
                                                        : ''}`}
                                                        value={formik.values.address}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.address && formik.touched.address && <div className="invalid-feedback">{formik.errors.address}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="pincode">Pincode</label>
                                                    <input
                                                        type="text"
                                                        id="pincode"
                                                        name="pincode"
                                                        className={`form-control ${formik.errors.pincode && formik.touched.pincode ? 'is-invalid' : ''}`}
                                                        value={formik.values.pincode}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.pincode && formik.touched.pincode && <div className="invalid-feedback">{formik.errors.pincode}</div>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="state">State</label>
                                                    <input
                                                        type="text"
                                                        id="state"
                                                        name="state"
                                                        className={`form-control ${formik.errors.state && formik.touched.state ? 'is-invalid' : ''}`}
                                                        value={formik.values.state}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.errors.state && formik.touched.state && <div className="invalid-feedback">{formik.errors.state}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Add input fields for other vendor data here */}
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          
          
        </div>
    );
}

export default EditVendor;
