import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import "./styles.css";

const Header = ({ sideBarEnable, setSideBarEnable }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="main-header">
      {/* Logo Section */}
      <div className="logo-header" data-background-color="dark">
        <Link to="/" className="logo">
          <img
            src="/assets/img/logo.svg"
            alt="navbar brand"
            className="navbar-brand"
          />
        </Link>
        <button
          className="navbar-toggler menu-toggle-btn"
          type="button"
          onClick={() => setSideBarEnable(!sideBarEnable)}
        >
          <FaBars size={20} />
        </button>
      </div>

      {/* Navbar */}
      <nav className="navbar navbar-header navbar-expand-lg" data-background-color="white">
        <div className="header-right">
          <ul className="navbar-nav topbar-nav ml-auto align-items-center">
            {/* Profile Dropdown */}
            <li className="nav-item dropdown">
              <button className="profile-btn" onClick={() => setDropdownOpen(!dropdownOpen)}>
                <div className="avatar-sm">
                  <img
                    src="/assets/img/profile.jpg"
                    alt="Profile"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <FaCaretDown className="dropdown-icon" />
              </button>
              
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
