import { Outlet } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import jsonData from "../../data/sideBar.json";
import jsonData1 from "../../data/sideBar1.json";
import { Context } from "../../context/Context";
import "./styles.css"; // Make sure to import your CSS file
import Footer from "../footer/Footer";

const Backend = () => {
  const [sideBarEnable, setSideBarEnable] = useState(true);
  const [sidebarData, setSidebarData] = useState(jsonData);
  const { userData } = useContext(Context);

  useEffect(() => {
    if (userData.role === "admin") {
      setSidebarData(jsonData);
    } else if (userData.role === "subAdmin") {
      setSidebarData(jsonData1);
    }
  }, [userData]);

  const toggleSidebar = () => {
    setSideBarEnable(!sideBarEnable);
  };

  return (
    <>
      <div
        className={`main-container ${
          !sideBarEnable ? "vertical-collpsed" : ""
        }`}
      >
        <div id="layout-wrapper">
          <Header
            sideBarEnable={sideBarEnable}
            setSideBarEnable={setSideBarEnable}
            toggleSidebar={toggleSidebar}
          />

          <Sidebar data={sidebarData} toggleSidebar={toggleSidebar} />
          <div className="main-panel">
            <div className="content">
              <Outlet />              
            </div>
            <Footer/>   
           
          </div>
        
        </div>
       
      </div>  
      
      
    </>
  );
};

export default Backend;
