import React, { useState, useEffect } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const AllEventsFestival = () => {
  const { getAllEventsFestival, approveVendorEvent } = useApiAxios();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMarketOwner, setSelectedMarketOwner] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Helper function to format date strings
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllEventsFestival();
        if (response && response.data?.status === "success") {
          setData(response.data.data);
        } else {
          console.error("Unexpected API response structure:", response);
          setData([]);
          setError("Unexpected API response structure.");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handler for when a market owner is selected
  const handleViewClick = (marketOwner) => {
    setSelectedMarketOwner(marketOwner);
  };

  // Refetch data for the selected market owner
  const refetchSelectedMarketOwner = async (marketOwnerId) => {
    try {
      const response = await getAllEventsFestival();
      if (response && response.data?.status === "success") {
        const updatedMarketOwner = response.data.data.find(
          (owner) => owner._id === marketOwnerId
        );
        setSelectedMarketOwner(updatedMarketOwner || null);
      }
    } catch (err) {
      console.error("Error refetching market owner data:", err);
      setError("Failed to refresh market owner data.");
    }
  };

  // Handler to approve an event/festival
  const handleApproveEvent = async (eventId, marketOwnerId) => {
    try {
      const response = await approveVendorEvent(eventId, "approved");
      if (response && response.data?.status === "success") {
        await refetchSelectedMarketOwner(marketOwnerId);
      }
    } catch (error) {
      console.error("Error approving event:", error);
    }
  };

  // Handler to disapprove an event/festival
  const handleDisapproveEvent = async (eventId, marketOwnerId) => {
    try {
      const response = await approveVendorEvent(eventId, "disapproved");
      if (response && response.data?.status === "success") {
        await refetchSelectedMarketOwner(marketOwnerId);
      }
    } catch (error) {
      console.error("Error disapproving event:", error);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  return (
    <div className="container">
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <ul className="breadcrumbs">
              <li className="nav-home">
                <Link to="/">
                  <i className="flaticon-home" />
                </Link>
              </li>
              <li className="separator">
                <i className="flaticon-right-arrow" />
              </li>
              <li className="nav-item">
                <a href="#">All Events & Festival</a>
              </li>
              <li className="separator">
                <i className="flaticon-right-arrow" />
              </li>
              <li className="nav-item">
                <a href="#">Events & Festival</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="row">
            {/* Left Column: Market Owners List */}
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table tabless">
                      <thead>
                        <tr>
                          <th>Market Owner</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((marketOwner) => (
                          <tr key={marketOwner._id}>
                            <td>{marketOwner.name}</td>
                            <td>
                              <button
                                className="btn btn-info btn-sm"
                                onClick={() => handleViewClick(marketOwner)}
                              >
                                View fest and events
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column: Events/Festivals for Selected Market Owner */}
            <div className="col-md-8">
              <div className="card shadow-lg border-0 rounded-lg">
                <div className="card-body">
                  {selectedMarketOwner ? (
                    <>
                      <h2
                        className="mb-4"
                        style={{
                          color: "#000",
                          fontSize: "24px",
                          fontWeight: "bold"
                        }}
                      >
                        Events & Festivals for {selectedMarketOwner.name}
                      </h2>
                      {selectedMarketOwner.events &&
                      selectedMarketOwner.events.length > 0 ? (
                        <div className="row">
                          {selectedMarketOwner.events.map((event) => (
                            <div key={event._id} className="col-lg-4 mb-4">
                              <div
                                className="card"
                                onClick={() => handleEventClick(event)}
                              >
                                <img
                                  src={`${process.env.REACT_APP_IMG_URL}/${event.image}`}
                                  alt={event.eventName}
                                  className="card-img-top"
                                  style={{
                                    objectFit: "cover",
                                    height: "200px"
                                  }}
                                />
                                <div className="card-body d-flex flex-column">
                                  <h5 className="card-title">
                                    {event.eventName}
                                  </h5>
                                  {/* <p className="card-text flex-grow-1">
                                    {event.description}
                                  </p> */}
                                  <p className="card-text">
                                    <strong>Start Date:</strong>{" "}
                                    {formatDate(event.startDate)} <br />
                                    <strong>End Date:</strong>{" "}
                                    {formatDate(event.endDate)}
                                  </p>

                                  {/* Approval Status Display */}
                                  <p className="card-text">
                                    <strong>Status:</strong>{" "}
                                    <span
                                      className={`badge ${
                                        event.approvalStatus === "approved"
                                          ? "bg-success"
                                          : event.approvalStatus ===
                                            "disapproved"
                                          ? "bg-danger"
                                          : "bg-warning"
                                      }`}
                                    >
                                      {event.approvalStatus || "Pending"}
                                    </span>
                                  </p>

                                  <div>
                                    <a
                                      href="#"
                                      className="btn btn-outline-success btn-sm"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent the modal from opening
                                        handleApproveEvent(
                                          event._id,
                                          selectedMarketOwner._id
                                        );
                                      }}
                                    >
                                      Accept
                                    </a>
                                    <a
                                      href="#"
                                      className="btn btn-outline-danger btn-sm ml-2"
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent the modal from opening
                                        handleDisapproveEvent(
                                          event._id,
                                          selectedMarketOwner._id
                                        );
                                      }}
                                    >
                                      Reject
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-muted">
                          No events or festivals available.
                        </p>
                      )}
                    </>
                  ) : (
                    <p className="text-muted">
                      Select a market owner to view events/festivals.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedEvent?.eventName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={`${process.env.REACT_APP_IMG_URL}/${selectedEvent?.image}`}
            alt={selectedEvent?.eventName}
            className="img-fluid mb-3"
          />
          <p>{selectedEvent?.description}</p>
          <p>
            <strong>Start Date:</strong> {formatDate(selectedEvent?.startDate)}
          </p>
          <p>
            <strong>End Date:</strong> {formatDate(selectedEvent?.endDate)}
          </p>
          <p>
            <strong>Status:</strong>{" "}
            {selectedEvent?.approvalStatus || "Pending"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllEventsFestival;
