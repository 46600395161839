import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
// import AdminRouting from "./components/AdminRouting";

import { Context } from "./context/Context";
import useApiAxios from "./api/useApiAxios";
import { useState } from "react";
import Login from "./login/Login";
import Dashboard from "./components/Dashboard";
import Loader from "./components/loader/Loader";
import SnackbarComponent from "./components/snackbarComponent/SnackbarComponent";
import Profile from "./components/profile/Profile";
import Backend from "./components/layout/Backend";
import ViewUser from "./components/user/ViewUser";
import CreateUser from "./components/user/CreateUser";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import ContactList from "./components/contactList/Contact";
import VendorList from "./components/vendor/VendorList";
import EditVendor from "./components/vendor/EditVendor";
import Payments from "./components/sale/Payments";
import EmailTemplates from "./components/emailTemplates/EmailTemplates";
import AddEmailTemplates from "./components/emailTemplates/AddEmailTemplates";
import EditEmailTemplates from "./components/emailTemplates/EditEmailTemplates";
import AllUser from "./components/allUser/AllUser";
import AllEventsFestival from "./components/allEventsFestival/AllEventsFestival";
// import ListLogs from "./components/listLogs/ListLogs";
const App = () => {
	const { isLoading, isAuthorized } = useContext(Context);
	const [firstLoading, setFirstLoading] = useState(false);
	const { homeApi } = useApiAxios();
	useEffect(() => {
		checkLogin();
	}, []);
	const checkLogin = async () => {
		setFirstLoading(true);
		await homeApi();
		setFirstLoading(false);
	};
	return (
		<>
			<Loader open={isLoading} />
			<SnackbarComponent />
			{firstLoading ? (
				<></>
			) : isAuthorized ? (
				<Routes>
					<Route path="/" element={<Backend />}>
						<Route path="/" element={<Dashboard />} />
						<Route path="/profile" element={<Profile />} />
						<Route path="/manageUser/viewUser" element={<ViewUser />} />
						<Route path="/manageUser/createUser" element={<CreateUser />} />
						<Route path="/contactList" element={<ContactList />} />
						<Route path="/vendorlist" element={<VendorList />} />
						<Route path="/editvendor/:id" element={<EditVendor />} />
						<Route path="/sale/payment" element={<Payments />} />
						<Route path="/email-templates" element={<EmailTemplates />} />
						<Route path="/create-email-templates" element={<AddEmailTemplates />} />
						<Route path="/update-email-templates/:template_id" element={<EditEmailTemplates />} />
						<Route path="/sendNotice" element={<AllUser />} />
						<Route path="/events-festivals" element={<AllEventsFestival />} />

						{/* <Route path="/error-logs" element={<ListLogs />} /> */}

						{/* <Route path="/priceupdate" element={<PriceUpdate />} /> */}
					</Route>
				</Routes>
			) : (
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/forgetPassword" element={<ForgetPassword />} />
				</Routes>
			)}
		</>
	);
};

export default App;
