import './styles.css'; 

import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios"; // Import API hook
const Sidebar = ({ data, toggleSidebar }) => {

  const [activeDropdown, setActiveDropdown] = useState(null);
  const { logOutApi } = useApiAxios(); // Get logout function
  const navigate = useNavigate(); // Hook for navigation

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleMenuClick = () => {
    if (window.innerWidth <= 991) {
      toggleSidebar();
    }
  };
  const handleLogout = async () => {
    await logOutApi(); // Call logout API
    navigate("/"); // Redirect to login page
  };

  return (
    <div className="sidebar sidebar-style-2" data-background-color="dark">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
      
        <div className="sidebar-content">
          <div className="user">
            <div className="avatar-sm float-left mr-2">
              <img src="/assets/img/profile.jpg" alt="..." className="avatar-img rounded-circle" />
            </div>
            <div className="info">
              <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
               
                  <span>
                  {data[0].name}
                  <span>
                    {data[0].title}
                    {/* <span className="user-level">{data[0].logo}</span> */}
                    <span className="caret"></span>
                  </span>
                  </span>
              </a>
              <div className="clearfix"></div>

              <div className="collapse in" id="collapseExample">

                <ul className="nav">
                  {data[0].subTitle.map((subItem, index) => (

                    <li key={index}>
                      <Link to={subItem.slug}>
                        <span className="link-collapse">{subItem.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>


          <ul className="nav nav-primary">
            <li class="nav-item active">
              <Link to="/" onClick={handleMenuClick}>
                <i class="fas fa-home"></i>
                <p>Dashboard</p>
              </Link>
            </li>
            <li class="nav-section">
              <span class="sidebar-mini-icon">
                <i class="fa fa-ellipsis-h"></i>
              </span>
              <h4 class="text-section">Components</h4>
            </li>

            {data.slice(1).map((item, index) => (
              <li className="nav-item" key={index}>
                <div>
                  <Link
                    data-toggle="collapse"
                    href={`#base${index}`}
                    onClick={() => toggleDropdown(index)}
                  >
                    <i className={item.logo}></i>
                    <p>{item.title}</p>
                    <span
                      className={`caret${activeDropdown === index ? ' open' : ''}`}
                    ></span>
                  </Link>

                  <div
                    className={`collapse${activeDropdown === index ? ' show' : ''}`}
                    id={`base${index}`}
                  >
                    {item.subTitle && item.subTitle.length > 0 && (
                      <ul className="nav nav-collapse">
                        {item.subTitle.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Link 
                              to={subItem.slug} 
                              className="dropdown-item"
                              onClick={handleMenuClick}
                            >
                              <span className="sub-item">{subItem.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </li>
            ))}

            <li className="nav-item logout">
              <button className="logout-btn" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt"></i>
                <p>Logout</p>
              </button>
            </li>
          </ul>

        </div>
       
      </div>
    </div>
  );
}

export default Sidebar;
