import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Footer from "../footer/Footer";

const Payments = () => {
  const { userData } = useContext(Context);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalMarkets, setTotalMarkets] = useState(0);
  const {
    getAllPaymentApi,
    getMarketPrice,
    updateMarketPrice,
    searchAdminApi
  } = useApiAxios();

  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [marketPrice, setMarketPrice] = useState("");
  const [newMarketPrice, setNewMarketPrice] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchPayments = async () => {
      setIsLoading(true);
      const { error, data } = await getAllPaymentApi(currentPage, rowsPerPage); // Subtract 1 for 0-based index
      setIsLoading(false);

      if (!error) {
        setPayments(data.data);
        setTotalMarkets(data.totalMarkets);
      } else {
        console.log("Error fetching payments", data);
      }
    };

    fetchPayments();
  }, [userData, currentPage, rowsPerPage]);

  const fetchMarketPrice = async () => {
    const { error, data } = await getMarketPrice();
    if (!error) {
      setMarketPrice(data.data.unit_amount);
    } else {
      console.log("Error fetching market price");
    }
  };

  useEffect(() => {
    fetchMarketPrice();
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1); // Add 1 to convert to 1-based index
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page on change
  };

  const handleMarketPriceChange = (event) => {
    setNewMarketPrice(event.target.value);
  };

  const handleUpdateMarketPrice = async () => {
    const priceInCents = newMarketPrice;
    const formData = { price: priceInCents };
    const { error } = await updateMarketPrice(formData);

    if (!error) {
      setNewMarketPrice("");
      fetchMarketPrice();
    } else {
      console.log("Error updating market price");
    }
  };

  const handleSearch = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query === "") {
      setCurrentPage(1); // Reset to first page on clear search
      setTotalMarkets(0); // Reset totalMarkets
      const { error, data } = await getAllPaymentApi(1, rowsPerPage); // Fetch initial payments
      if (!error) {
        setPayments(data.data);
        setTotalMarkets(data.totalMarkets);
      }
    } else {
      try {
        const response = await searchAdminApi(query);
        setPayments(response.data.data);
        setTotalMarkets(response.data.totalMarkets);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error searching:", error);
        setTotalMarkets(0); // Reset totalMarkets in case of error
      }
    }
  };

  useEffect(() => {
    const fetchPayments = async () => {
      setIsLoading(true);
      const { error, data } = await getAllPaymentApi(currentPage, rowsPerPage);
      setIsLoading(false);

      if (!error) {
        setPayments(data.data);
        setTotalMarkets(data.totalMarkets);
      } else {
        console.log("Error fetching payments", data);
      }
    };

    fetchPayments();
  }, [userData, currentPage, rowsPerPage]);

  const pageCount = Math.ceil(totalMarkets / rowsPerPage);

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Payments</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Sales</a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Payments</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="card-title">All Payments</h4>
                <div className="form-inline">
                  <label htmlFor="marketPrice">Current Market Price: </label>
                  <input
                    type="text"
                    className="form-control mr-2"
                    placeholder="Market Price"
                    value={marketPrice / 100}
                    readOnly
                  />
                  <input
                    type="number"
                    className="form-control mr-2"
                    placeholder="Enter new price"
                    value={newMarketPrice}
                    onChange={handleMarketPriceChange}
                  />
                  <button
                    className="btn btn-primary mr-2"
                    onClick={handleUpdateMarketPrice}
                  >
                    Update Price
                  </button>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Market Name</th>
                        <th>Amount</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.map((payment, index) => (
                        <tr key={index}>
                          <td>{payment?.name}</td>
                          {payment.subscription &&
                          payment.subscription.length > 0 ? (
                            <>
                              <td>${payment?.subscription[0]?.amount}</td>
                              <td>
                                {moment(payment?.createdAt).format(
                                  "DD-MY-YYYY"
                                )}
                              </td>
                            </>
                          ) : (
                            <td colSpan="2">
                              Not subscribed yet / On 7 Day Free trial
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between align-items-center pagination-container">
                  <div className="page-info">
                    Page {currentPage} of {pageCount}
                  </div>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    subContainerClassName={"pages pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakLinkClassName={"page-link"}
                  />
                  <div
                    className="form-group d-flex align-items-center items-per-page"
                    style={{ marginLeft: "20px" }}
                  >
                    <label htmlFor="rowsPerPage" className="mr-2">
                      Rows per page:
                    </label>
                    <select
                      id="rowsPerPage"
                      className="form-control"
                      value={rowsPerPage}
                      onChange={handleRowsPerPageChange}
                      style={{ width: "auto", display: "inline-block" }}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;
