import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { useNavigate } from "react-router-dom";

const VendorList = () => {
  const { getAllVendor, deleteVendorApi } = useApiAxios();
  const [vendorData, setVendorData] = useState([]); // State to store the selected course ID
  const navigate = useNavigate();

  const fun = async () => {
    getAllVendor()
      .then((response) => {
        setVendorData(response.data.data);
      })
      .catch((error) => {
        setVendorData([]);
        // Handle error, e.g., show an error message
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fun();
  }, []);

  const handleEdit = (vendor) => {
    navigate(`/editvendor/${vendor._id}`, { state: { vendor } });
  };

  return (
    <div>
      
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="pb-2 fw-bold">Dashboard</h2>
                  <ul className="breadcrumbs">
                    <li className="nav-home">
                      <a href="#">
                        <i className="flaticon-home" />
                      </a>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <a href="#">Dashboard</a>
                    </li>
                  </ul>
                </div>
                <div className="ml-md-auto py-2 py-md-0">
                  <div className="row">
                    <div className="col-6">
                      <input
                        type="text"
                        name="dates"
                        className="form-control pull-right"
                      />
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="#"
                            className="btn btn-primary btn-border btn-round mr-2"
                          >
                            <span className="btn-label">
                              <i className="fa fa-file-excel" />
                            </span>
                            Export
                          </a>
                        </div>
                        <div className="col-6">
                          <a href="#" className="btn btn-primary btn-round">
                            <span className="btn-label">
                              <i className="fa fa-print" />
                            </span>
                            Print
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-primary card-round">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-users" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category">Total Users</p>
                          <h4 className="card-title">1,294</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-info card-round">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-interface-6" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category">Subscribers</p>
                          <h4 className="card-title">1303</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-success card-round">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-analytics" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category">Sales</p>
                          <h4 className="card-title">$ 1,345</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-secondary card-round">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-success" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category">Active Users</p>
                          <h4 className="card-title">576</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                      <h4 className="card-title">Vendors</h4>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="modal fade"
                      id="addRowModal"
                      tabIndex={-1}
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header no-bd">
                            <h5 className="modal-title">
                              <span className="fw-mediumbold">New</span>
                              <span className="fw-light">Row</span>
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <p className="small">
                              Create a new row using this form, make sure you
                              fill them all
                            </p>
                            <form>
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group form-group-default">
                                    <label>Name</label>
                                    <input
                                      id="addName"
                                      type="text"
                                      className="form-control"
                                      placeholder="fill name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 pr-0">
                                  <div className="form-group form-group-default">
                                    <label>Position</label>
                                    <input
                                      id="addPosition"
                                      type="text"
                                      className="form-control"
                                      placeholder="fill position"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group form-group-default">
                                    <label>Office</label>
                                    <input
                                      id="addOffice"
                                      type="text"
                                      className="form-control"
                                      placeholder="fill office"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer no-bd">
                            <button
                              type="button"
                              id="addRowButton"
                              className="btn btn-primary"
                            >
                              Add
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="add-row"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                            <th>Pincode</th>
                            <th>State</th>
                            <th style={{ width: "10%" }}>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {vendorData.map((vendor) => (
                            <tr key={vendor._id}>
                              <td>{vendor.name || "Not Available"}</td>
                              <td>{vendor.email || "Not Available"}</td>
                              <td>{vendor.address || "Not Available"}</td>
                              <td>{vendor.pincode || "Not Available"}</td>
                              <td>{vendor.state || "Not Available"}</td>
                              <td>
                                <div className="form-button-action">
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn btn-link btn-primary btn-lg"
                                    onClick={() => handleEdit(vendor)}
                                    data-original-title="Edit Task"
                                  >
                                    <i className="fa fa-edit" />
                                  </button>

                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn btn-link btn-danger"
                                    data-original-title="Remove"
                                    onClick={async () => {
                                      await deleteVendorApi(vendor._id);
                                      await fun();
                                    }}
                                  >
                                    <i className="fa fa-times" />
                                  </button>
                                  <div className="col-6">
                                    <button className="btn btn-primary btn-round">
                                      Approve
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
  );
};

export default VendorList;
